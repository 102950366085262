@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Robot Crush;
  src: url("./assets/RobotCrush.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: PressStart2P;
  src: url("./assets/PressStart2P.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@layer components {
  .center {
    @apply flex items-center justify-center;
  }
}

.title {
  font-family: "Robot Crush";
}
.tech {
  font-family: "PressStart2P", "Press Start 2P", monospace;
}
.active {
  color: #d50000 !important;
}
button.menu {
  position: relative;
  padding: 0;
  align-items: center;
  justify-content: center;
  border-width: 0 !important;
  margin-right: 1.5rem;
  background: none;
  width: 1.5rem;
  height: 2rem;
  border-color: rgba(0, 0, 0, 0);
  appearance: none;
  outline: none;
}
button.menu div {
  position: relative;
  width: 1.5rem;
  height: 12px;
  transition: 0.085s all ease-in;
  cursor: pointer;
  display: flex;
}
button.menu div span {
  position: absolute;
  height: 2px;
  width: 1.5rem;
  -webkit-transform-origin: left;
  -ms-transform-origin: left;
  transform-origin: left;
  display: block;
  transition: 0.085s all ease-in;
  border-radius: 100px;
  background-color: #212121;
}
button.menu div span.top {
  top: 0;
}
button.menu div span.bottom {
  bottom: 0;
}
button.menu :hover span.top {
  width: 1rem;
}
button.menu.true div {
  width: 18px;
  height: 19px;
}
button.menu.true span.top {
  transform-origin: left center;
  width: 1.5rem;
  transform: rotate(45deg);
}
button.menu.true span.bottom {
  transform-origin: left center;
  transform: rotate(-45deg);
}
